import React, { useEffect, useState, useRef } from "react"
import tw, {css} from "twin.macro"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import breakpoints from "../../styles/breakpoints"
import mq from '../../styles/media-queries'
import useScript from '../../hooks/useScript'
import loadable from '@loadable/component'

const windowGlobal = typeof window !== 'undefined' && window;

const adSizes = [
	[300, 600]
]

const getBannerAdUnit = (code) => {
	return {
		code,
		mediaTypes: {
			banner: {
				sizes: adSizes
			}
		},
		bids: [
			{
				bidder: 'bidglass',
				params: {
					adUnitId: '56'
				}
			},
			{
				bidder: 'apacdex',
				params: {
					siteId: '246759', // siteId provided by Apacdex
					floorPrice: 0.05, // default is 0.01 if not declared,
				}
			}
		]
	}
}

const Ad = () => {

	const { width } = useWindowDimensions()
	const [visibleUnits, setVisibleUnits] = useState(0)
	const [renderedUnits, setRenderedUnits] = useState(0)
	const [videoBid, setVideoBid] = useState(null)

	const minWidth3 = breakpoints.find(bp => bp.xl).xl
	const minWidth2 = breakpoints.find(bp => bp.lg).lg
	const minWidth1 = breakpoints.find(bp => bp.md).md

	const [playerWidth, setPlayerWidth] = useState(640)
	const [playerHeight, setPlayerHeight] = useState(360)

 	useEffect(() => {
		
		if (adAreaRef.current) {

			const playerWidths = [1920,1280,854,640]
			const playerHeights = [1080,720,480,360]
			let i = 0
			
			while (playerWidths[i] > (adAreaRef.current.offsetWidth) && i < playerWidths.length) { i++ }

			setPlayerWidth(playerWidths[i])
			setPlayerHeight(playerHeights[i])

		}

	}, [])

	const tagStatus = useScript('https://www.googletagservices.com/tag/js/gpt.js')
	const prebidStatus = useScript(`${process.env.GATSBY_SITE_URL}/scripts/prebid7.18.0.js`)
	const viStatus = useScript('//services.vlitag.com/adv1/?q=912eab4757ede53d9b6beaae499229cc')

	// const Video = React.useMemo(() => {
	// 	if (windowGlobal && videoBid) {
	// 		// return loadable(() => import(`./video`))
	// 		return loadable(() => import(`../../components/adPlayerProVideo`))
	// 	}
	// }, [windowGlobal, videoBid])
	
	useEffect(() => {

		if (viStatus !== 'ready') {
			return
		}

		var vitag = vitag || {}
		vitag.outStreamConfig = { type: "slider", position: "right" }

		vitag.Init = window.vitag.Init || []
		vitag.Init.push(function () { viAPItag.display("vi_23157100654") })

		// <div style="height: 250px;" class="adsbyvli" data-ad-slot="outstream"></div> <script> ; </script>

	}, [viStatus])

	// useEffect(() => {

	// 	if (tagStatus !== 'ready') {
	// 		return
	// 	}

	// 	var googletag = window.googletag || {};
	// 	googletag.cmd = googletag.cmd || [];
	// 	googletag.cmd.push(function () {
	// 		googletag.pubads().disableInitialLoad();
	// 	});

	// 	googletag.cmd.push(function () {
	// 		googletag.destroySlots()
	// 		window.slots = {}
	// 		window.slots.video = googletag.defineSlot('/22175459031/fileio-desktop-video', [[1,1]], 'video-ad').addService(googletag.pubads());
	// 		window.slots.halfpage1 = googletag.defineSlot('/22175459031/fileio-halfpage-gallery-1', adSizes, 'fileio-halfpage-gallery-1').addService(googletag.pubads());
	// 		window.slots.halfpage2 = googletag.defineSlot('/22175459031/fileio-halfpage-gallery-2', adSizes, 'fileio-halfpage-gallery-2').addService(googletag.pubads());
	// 		window.slots.halfpage3 = googletag.defineSlot('/22175459031/fileio-halfpage-gallery-3', adSizes, 'fileio-halfpage-gallery-3').addService(googletag.pubads());
	// 		googletag.pubads().enableSingleRequest();
	// 		googletag.enableServices();
	// 	});

	// }, [tagStatus])

	// useEffect(() => {

	// 	if (renderedUnits >= visibleUnits || tagStatus !== 'ready' || prebidStatus !=='ready') {
	// 		return
	// 	}

	// 	const PREBID_TIMEOUT = 1000;
	// 	const FAILSAFE_TIMEOUT = 3000;
	// 	const adUnitCodes = []

	// 	for (let u = visibleUnits; u > renderedUnits; u--) {
	// 		adUnitCodes.push(`fileio-halfpage-gallery-${u}`)
	// 	}

	// 	const adUnits = adUnitCodes.map((code) => getBannerAdUnit(code))
	// 	adUnits.push({
	// 		code: '/22175459031/fileio-desktop-video',
	// 		mediaTypes: {
	// 			video: {
	// 				playerSize: [[playerWidth,playerHeight]],
	// 				context: "outstream",
	// 				api: [2],
	// 				placement: 2,
	// 				linearity: 1,
	// 				minduration: 1,
	// 				maxduration: 120,
	// 				mimes: ["video/mp4", "video/x-flv", "video/x-ms-wmv", "application/vnd.apple.mpegurl", "application/x-mpegurl", "video/3gpp", "video/mpeg", "video/ogg", "video/quicktime", "video/webm", "video/x-m4v", "video/ms-asf", "video/x-msvideo"],
	// 				playbackmethod: [6],
	// 				startdelay: 0,
	// 				protocols: [1, 2, 3, 4, 5, 6],
	// 				renderer: {
	// 					render: (bid) => {
	// 						setVideoBid(bid)
	// 					},
	// 					url: '/scripts/noop.js',
	// 					backupOnly: true
	// 				},
	// 			}
	// 		},
	// 		bids: [
	// 			{
	// 				bidder: 'apacdex',
	// 				params: {
	// 					siteId: '246759', // siteId provided by Apacdex
	// 					floorPrice: 0.50, // default is 0.01 if not declared,
	// 				}
	// 			}
	// 		]
	// 	})

	// 	var pbjs = window.pbjs || {};
	// 	pbjs.initAdserverSet = pbjs.initAdserverSet || 0;
	// 	pbjs.que = pbjs.que || [];

	// 	pbjs.que.push(function () {
	// 		pbjs.setConfig({
	// 			// debug: true,
	// 			cache: {
	// 				url: 'https://prebid.adnxs.com/pbc/v1/cache'
	// 			},
	// 			priceGranularity: 'auto',
	// 			userSync: {
	// 				filterSettings: {
	// 					iframe: {
	// 						bidders: ['apacdex'],
	// 						filter: 'include'
	// 					}
	// 				}
	// 			}
	// 		});
	// 		pbjs.removeAdUnit()
	// 		pbjs.addAdUnits(adUnits);
	// 		pbjs.requestBids({
	// 			bidsBackHandler: initAdserver,
	// 			timeout: PREBID_TIMEOUT
	// 		});
	// 	});
	
	// 	function initAdserver(bidResponse) {
	// 		console.log(bidResponse)
	// 		if (pbjs.initAdserverSet >= visibleUnits) return;
	// 		pbjs.initAdserverSet = visibleUnits;
	// 		googletag.cmd.push(function () {
	// 			pbjs.que.push(function () {

	// 				pbjs.setTargetingForGPTAsync();

	// 				const videoBids = pbjs.getHighestCpmBids('/22175459031/fileio-desktop-video')
					
	// 				if (videoBids && videoBids.length) {

	// 					try {

	// 						const fd = new FormData()
							
	// 						fd.append("name",`fileio-bidresponse-${(new Date()).toISOString()}`)
	// 						fd.append("text",JSON.stringify({
	// 							"platform": "desktop",
	// 							"bidResponse": bidResponse	
	// 						}))
							
	// 						fetch("https://file.io", {
	// 							method: "post",
	// 							body: fd
	// 						})
	
	// 					}
	// 					catch(err) {}
	

	// 					googletag.pubads().refresh([window.slots.video]);
	// 				}
	// 				else {
	// 					showBannerAds()
	// 				}

	// 			});
	// 		});
	// 	}

	// 	// in case PBJS doesn't load
	// 	setTimeout(function () {
	// 		initAdserver();
	// 	}, FAILSAFE_TIMEOUT);

	// 	setRenderedUnits(visibleUnits)

	// }, [visibleUnits, tagStatus, prebidStatus])

	useEffect(() => {
		if (width >= minWidth3) {
			setVisibleUnits(3)
		}
		else if (width >= minWidth2) {
			setVisibleUnits(2)
		}
		else if (width >= minWidth1) {
			setVisibleUnits(1)
		}
		else {
			setVisibleUnits(0)
		}
		console.log('visibleUnits', visibleUnits)
	}, [width])

	function showBannerAds() {
		setBannerAds(true)
		const slots = []
		for(let i=1; i <= visibleUnits; i++) {
			slots.push(window.slots['halfpage' + i])
		}
		googletag.pubads().refresh(slots);
	}

	const [bannerAds, setBannerAds] = useState(false)

	const handleVideoDone = () => {
		showBannerAds()
	}

	const adAreaRef = useRef()

	return (<>
		<div tw="absolute w-full h-full hidden md:block bg-blue pt-14 pr-6" css={[
			{
				paddingLeft: "21rem"
			}
		]}>
			<div ref={adAreaRef} tw="h-full w-full bg-blue flex items-center justify-center">
				<div tw="" style={{"width":`${playerWidth}px`, "height":`${playerHeight}px`}} className="adsbyvli" data-ad-slot="outstream"></div>
				{/* <div tw="" style={{"width":`${playerWidth}px`, "height":`${playerHeight}px`}} className="adsbyvli" data-ad-slot="vi_23157100654"> */}
			</div>
		</div>
		{/* <div tw="absolute w-full h-full hidden md:flex items-center justify-center bg-blue pt-14 pr-6" css={[
			{
				paddingLeft: "21rem"
			}
		]}>
			<div 
				tw="pointer-events-none flex-1 grid"
				css={[
					{
						[mq.md] : { 
							...tw`grid-cols-1`
						},
						[mq.lg] : { 
							...tw`grid-cols-2`
						},
						[mq.xl] : { 
							...tw`grid-cols-3`
						}

					}
				]}
			>
				<div tw="flex justify-center">
					<div id="fileio-halfpage-gallery-1" tw="w-0 h-0 invisible md:visible" css={[
						{
							[mq.md] : { 
								width:"300px",
								height:"600px"
							}
						},
						bannerAds && { ...tw`bg-black bg-opacity-10` }
					]}></div>
				</div>
				<div tw="flex justify-center">
					<div id="fileio-halfpage-gallery-2" tw="w-0 h-0 invisible lg:visible" css={[
						{
							[mq.lg] : { 
								width:"300px",
								height:"600px"
							}
						},
						bannerAds && { ...tw`bg-black bg-opacity-10` }
					]}></div>
				</div>
				<div tw="flex justify-center">
					<div id="fileio-halfpage-gallery-3" tw="w-0 h-0 invisible xl:visible" css={[
						{
							[mq.xl] : { 
								width:"300px",
								height:"600px"
							}
						},
						bannerAds && { ...tw`bg-black bg-opacity-10` }
					]}></div>
				</div>
			</div>
		</div> */}
	</>)
}

export default Ad